import ReactDOM from 'react-dom'
import axios, { AxiosError, AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'
import { DataService } from 'utils/DataService'
import emptyFunction from 'utils/emptyFunction'
import { getOktaToken } from 'utils/getOktaToken'
import { rethrowError } from 'utils/rethrowError'
import sentry from 'utils/sentry'

const { captureException } = sentry()

type Callback<R = any> = (result: R) => void
type ErrCallback = (result: AxiosResponse & AxiosError) => void

export function create<R>(
  baseUrl,
  url,
  props,
  onSuccess: Callback | null = null,
  onError: ErrCallback | null = null,
  withAuth = true,
  withCredentials = false,
  shouldRethrowError = true,
  shouldCaptureException = false,
) {
  return new DataService(baseUrl, withAuth, withCredentials)
    .create<unknown, R>(url, props)
    .subscribe({
      next: (...args) => {
        onSuccess?.(...args)
      },
      error: (...args) => {
        if (shouldCaptureException) {
          const errorMessage = args[0]?.message ?? 'Network error'
          const statusCode = args[0]?.response?.status

          captureException(
            { message: errorMessage, statusCode },
            {
              errorInfo: ['Error raised from utils/http/POSTorCREATE'],
              query: url,
            },
          )
        }

        if (shouldRethrowError) {
          rethrowError(...args)
        }
        if (onError) {
          onError(...args)
        }
      },
    })
}

export function update<R>(
  baseUrl,
  url,
  props,
  onSuccess: Callback | null = null,
  onError: ErrCallback | null = null,
  withAuth = true,
  withCredentials = false,
) {
  return new DataService(baseUrl, withAuth, withCredentials)
    .update<unknown, R>(url, props)
    .subscribe({
      next: (...args) => {
        ReactDOM.unstable_batchedUpdates(() => {
          onSuccess && onSuccess(...args)
        })
      },
      error: (...args) => {
        rethrowError(...args)
        if (onError) {
          onError(...args)
        }
      },
    })
}

export function delete_<R>(
  baseUrl,
  url,
  onSuccess: Callback | null = null,
  onError: ErrCallback | null = null,
  withAuth = true,
  withCredentials = false,
) {
  return new DataService(baseUrl, withAuth, withCredentials)
    .delete<R>(url)
    .subscribe({
      next: (...args) => {
        ReactDOM.unstable_batchedUpdates(() => {
          onSuccess && onSuccess(...args)
        })
      },
      error: e => {
        if (onError) {
          onError(e)
        } else {
          rethrowError(e)
        }
      },
    })
}

type GetReqestConfig = {
  url?: string
  body: object
}
export function get<R>(
  baseUrl: string,
  urlOrConfig: string | GetReqestConfig,
  onSuccess: Callback | null = null,
  onError: ErrCallback | null = null,
  withAuth = true,
  withCredentials = false,
  shouldCaptureException = false,
) {
  let retryOnErrorCb
  const dsObject = new DataService(baseUrl, withAuth, withCredentials)
  const ds =
    typeof urlOrConfig === 'string'
      ? dsObject.getData<R>(urlOrConfig)
      : dsObject.create<unknown, R>(urlOrConfig.url ?? '', urlOrConfig.body)
  const sub = ds.subscribe({
    next: (...args) => {
      if (cancelled) {
        return
      }
      onSuccess?.(...args)
    },
    error: (...args) => {
      if (shouldCaptureException) {
        const errorMessage = args[0]?.message ?? 'Network error'
        const statusCode = args[0]?.response?.status

        captureException(
          { message: errorMessage, statusCode },
          {
            errorInfo: ['Error raised from utils/http/GET'],
            query: urlOrConfig,
          },
        )
      }
      if (cancelled) {
        return
      }
      rethrowError(...args)
      console.error(...args)
      onError?.(...args)
      if (retryOnErrorCb) {
        retryOnErrorCb()
      }
    },
  })
  let cancelled = false
  const ret = {
    ds,
    cancel: () => {
      cancelled = true
      sub.unsubscribe()
    },
    retry: (ms = 2000, times = 3) => {
      if (times <= 0) {
        retryOnErrorCb = null
        return ret
      }
      retryOnErrorCb = () => {
        if (times--) {
          setTimeout(() => {
            get(
              baseUrl,
              urlOrConfig,
              onSuccess,
              onError,
              withAuth,
              withCredentials,
            )
          }, ms)
        } else {
          retryOnErrorCb = null
        }
      }
      return ret
    },
  }
  return ret
}

export const post = create

export function fetchableOnce(fetcher) {
  let isFetching = false
  const queue = []
  let value
  const setValue = data => {
    isFetching = false
    value = data
    while (queue.length) {
      queue.shift()(value)
    }
  }
  return (onSuccess = emptyFunction, onError = emptyFunction) => {
    if (value) {
      onSuccess(value)
    } else if (isFetching) {
      queue.push(onSuccess)
    } else {
      isFetching = true
      fetcher(value => {
        setValue(value)
        onSuccess(value)
      }, onError)
    }
  }
}

type RequestParams = {
  [key: string]: unknown
}
type VoidCB = (payload: unknown) => void

export function fetchableOnceWithParams(
  fetcher: (
    params: RequestParams,
    onSuccess?: VoidCB,
    onError?: VoidCB,
  ) => unknown,
) {
  let isFetching = false
  const successCbQueue = []
  let store: {
    payload: unknown
    params: RequestParams
  }
  const setStore = (payload: unknown, params: RequestParams) => {
    store = { payload, params }
    while (successCbQueue.length) {
      successCbQueue.shift()(store.payload)
    }
  }
  return (
    params: RequestParams,
    onSuccess: VoidCB = emptyFunction,
    onError: VoidCB = emptyFunction,
  ) => {
    if (store && isEqual(store.params, params)) {
      onSuccess(store.payload)
    } else if (isFetching) {
      successCbQueue.push(onSuccess)
    } else {
      isFetching = true
      fetcher(
        params,
        payload => {
          isFetching = false
          setStore(payload, params)
          onSuccess(payload)
        },
        response => {
          isFetching = false
          onError(response)
        },
      )
    }
  }
}

export async function getWithAuth(baseUrl: string, path: string) {
  const instance = axios.create({
    baseURL: baseUrl,
    responseType: 'json',
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
    },
  })

  const token = await getOktaToken()

  instance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  const response = await instance.get(path)
  return response.data
}

export function createAsync<T, E = AxiosError>(baseURL: string, path: string) {
  return <R>(params: R) =>
    new Promise<T>((resolve, reject) => {
      try {
        const subscription = create<T>(
          baseURL,
          path,
          params,
          result => {
            resolve(result)
            subscription.unsubscribe()
          },
          (error: E | AxiosError) => {
            reject(error)
            subscription.unsubscribe()
          },
          true,
          false,
          false,
        )
      } catch (error) {
        reject(error)
      }
    })
}
// since msw does not work well with the observable
// for new post call or post refactoring, should use this
export async function makePostCall<R>({
  body,
  customHeaderOptions,
  url,
  withAuth = true,
}: {
  body?: Record<string, unknown> | string | number
  customHeaderOptions?: Record<string, unknown>
  url: string
  withAuth?: boolean
}) {
  const headers = {
    'Content-Type': 'application/json',
    ...customHeaderOptions,
  }
  const res = await fetch(url, {
    method: 'POST',
    headers: withAuth
      ? {
          Authorization: `Bearer ${await getOktaToken()}`,
          ...headers,
        }
      : headers,
    body: JSON.stringify(body),
  })

  if (res.ok) {
    return res.json() as R
  } else {
    const { error, ...otherErrorBody } = await res.json()
    if (error) {
      throw new Error(error)
    }
    throw otherErrorBody
  }
}

export function patch<R>(
  baseUrl: string,
  url: string,
  props: object,
  onSuccess: Callback<R> | null = null,
  onError: ErrCallback | null = null,
  withAuth = true,
  withCredentials = false,
) {
  return new DataService(baseUrl, withAuth, withCredentials)
    .patch<unknown, R>(url, props) // Assuming DataService has a patch method
    .subscribe({
      next: (...args) => {
        ReactDOM.unstable_batchedUpdates(() => {
          onSuccess && onSuccess(...args)
        })
      },
      error: e => {
        if (onError) {
          onError(e)
        } else {
          rethrowError(e)
        }
      },
    })
}
