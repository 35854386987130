import { MessageBus } from '@galaxy-digital/message-bus'
import Router from 'next/router'
import { singleLogout } from './auth'
import { isBrowser } from './isBrowser'

type MessagePayloadType = 'g1-on-navigate'
type MessagePayloadUrl =
  | 'g1://settings'
  | 'g1://settings/addresses'
  | 'g1://settings/users'
  | 'g1://settings/resources'
  | 'g1://settings/sign-out'

type MessageEventPayload =
  | {
      type: MessagePayloadType
      url: MessagePayloadUrl
      params?: Record<string, string>
    }
  | {
      type: MessagePayloadType
      url: 'g1://settings/resources'
      params?: { slug: string }
    }

const handleOnNavigate = (message: MessageEventPayload) => {
  switch (message.url) {
    case 'g1://settings': {
      Router.push('/settings')
      break
    }
    case 'g1://settings/addresses': {
      Router.push('/settings/addresses')
      break
    }
    case 'g1://settings/users': {
      Router.push('/settings/users')
      break
    }
    case 'g1://settings/resources': {
      if (message.params?.slug) {
        Router.push(
          `/settings/resources/${encodeURIComponent(message.params.slug)}`,
        )
      } else {
        Router.push('/settings/resources')
      }
      break
    }
    case 'g1://settings/sign-out': {
      singleLogout()
      break
    }
  }
}

const handleMessage = (ev: MessageEvent<MessageEventPayload>) => {
  if (ev.data.type) {
    switch (ev.data.type) {
      case 'g1-on-navigate': {
        return handleOnNavigate(ev.data)
      }
    }
  }
}

export const registerMessageLoop = () => {
  if (isBrowser) {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }
}

const signOutFromApp = (signOutUrl: string): Promise<void> =>
  new Promise(resolve => {
    const iframe = document.createElement('iframe')
    iframe.hidden = true
    iframe.src = signOutUrl

    let redirects = 0
    let lastRedirects = 0

    const onLoad = () => {
      redirects++
    }

    const timer = setInterval(() => {
      if (lastRedirects === redirects) {
        iframe.removeEventListener('load', onLoad)
        document.body.removeChild(iframe)
        clearInterval(timer)

        resolve()
      }
      lastRedirects = redirects
    }, 3000)

    iframe.addEventListener('load', onLoad)
    document.body.appendChild(iframe)
  })

export const signOutFromAll = (signOutUrls: string[]): Promise<void[]> =>
  Promise.all(signOutUrls.map(signOutFromApp))

export const messageBus = new MessageBus()
