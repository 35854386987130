import React, { FC, useCallback, useMemo, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { Message } from 'semantic-ui-react'

import { EnvironmentBanner } from '@design-library/EnvironmentBanner'
import { G1Footer } from '@design-library/Footer'
import { BannerPlaceholder } from 'components/Banner/BannerManager/components/Placeholder'
import { LegalTerms, useGetUserConsent } from 'components/LegalTerms'
import { isG1, isLocal } from 'utils/envUtils'
import GlobalContext, { NotificationMessage } from 'utils/global-context'

import Styles from './AppContent.module.scss'

const defaultMessageState: NotificationMessage = {
  header: '',
  content: '',
  type: 'info',
}

type Props = {
  appHeader: React.ReactElement
  page: React.ReactElement
}

export const AppContent: FC<Props> = ({ appHeader, page }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [message, setMessage] =
    useState<NotificationMessage>(defaultMessageState)

  const onDismiss = useCallback<VoidFunction>(() => {
    setShowMessage(false)
    setMessage(defaultMessageState)
  }, [])

  const globalContextValue = useMemo(() => ({ setShowMessage, setMessage }), [])

  const { isLoading, data: userConsent } = useGetUserConsent({
    enabled: isG1() && !isLocal(),
  })

  if (isLoading) return <BannerPlaceholder />
  if (isG1() && !isLocal() && !userConsent?.consented) return <LegalTerms />

  return (
    <>
      {appHeader}
      <div className={Styles.container} id="secured-content-container">
        {showMessage && (
          <Message
            error={message.type === 'error'}
            info={message.type === 'info'}
            warning={message.type === 'warning'}
            negative={message.type === 'negative'}
            header={message.header}
            content={message.content}
            onDismiss={onDismiss}
          />
        )}
        <GlobalContext.Provider value={globalContextValue}>
          <ToastContainer enableMultiContainer limit={3} />
          {page}
          {isG1() && <EnvironmentBanner className={Styles.environmentBanner} />}
        </GlobalContext.Provider>
        {isG1() && <G1Footer />}
      </div>
    </>
  )
}
