import { FC } from 'react'
import { FooterLink, GalaxyOneProvider, Typography } from '@g1/components'
import { useRouter } from 'next/router'
import VestingDisclaimerFooter from '@design-library/Footer/VestingDisclaimerFooter'
import { get } from 'Gatekeeper'

import Styles from './Footer.module.scss'

const footerLinks: FooterLink[] = [
  { display: 'About Galaxy', url: 'https://www.galaxy.com/about/' },
  { display: 'Terms of Use', url: 'https://www.galaxy.com/terms/' },
  { display: 'Disclosures', url: 'https://www.galaxy.com/disclosures/' },
  { display: 'Privacy Policy', url: 'https://www.galaxy.com/privacy/' },
  { display: 'Contact Us', url: 'mailto:galaxyone@galaxy.com' },
  { display: 'Changelog', url: '/g1/changelog' },
]

export const G1Footer: FC = () => {
  const router = useRouter()
  const showVestingScheduleDisclaimer =
    router.pathname.startsWith('/g1/accounts') &&
    (router.query.unvested === 'true' || router.query.vested === 'true')

  const filteredLinks = footerLinks.filter(
    fl =>
      (fl.display === 'Changelog' && get('g1_show_changelog')) ||
      fl.display !== 'Changelog',
  )

  return (
    <div className={Styles.layoutFooter}>
      <GalaxyOneProvider theme="dark">
        {showVestingScheduleDisclaimer && <VestingDisclaimerFooter />}
        <div className={Styles.footer}>
          <div className={Styles.footerContainer}>
            <div className={Styles.footerLinks}>
              {filteredLinks.map(footerLink => (
                <a key={footerLink.url} href={footerLink.url}>
                  <Typography variant="body2">{footerLink.display}</Typography>
                </a>
              ))}
            </div>
            <Typography variant="metadata">
              {`© ${new Date().getFullYear()} Galaxy All Rights Reserved`}
            </Typography>
          </div>
          <div className={Styles.soc2Container}>
            <img
              className={Styles.soc2Image}
              src="/assets/SOC2.png"
              alt="SOC 2 Type 2 Compliant"
            />
            <Typography className={Styles.soc2Text} variant="metadata">
              GalaxyOne is SOC 2 Type 2 compliant under the American Institute
              of Certified Public Accountants (AICPA) standards for SOC for
              Service Organizations, also known as SSAE 18. For a copy of the
              report, please reach out to the GalaxyOne team.
            </Typography>
          </div>
        </div>
      </GalaxyOneProvider>
    </div>
  )
}
