import { Theme } from '@emotion/react'

export * from '@emotion/react'
export { default } from '@emotion/styled'
export * from '@emotion/styled'

export { default as appTheme } from './theme'
export * from './styled'

export type ThemeProps<P = object> = P & { theme: Theme }
