import { Theme } from '@emotion/react'

export const colors: Theme['colors'] = {
  white: {
    $01: '#FFF',
  },
  black: {
    $01: '#000',
  },
  grey: {
    light: {
      $01: '#b3b5bd',
      $02: '#8f9096',
      $03: '#50535c',
    },
    dark: {
      $01: '#313540',
      $02: '#1d222e',
      $03: '#131722',
    },
  },
  green: {
    $01: '#00a477',
    $02: '#1E7159',
    $03: '#054A37',
  },
  red: {
    $01: '#f26579',
    $02: '#A83D51',
    $03: '#4C1422',
  },
  yellow: {
    $01: '#D9D46B',
  },
  blue: {
    $01: '#069',
  },
}
