import { FC } from 'react'
import cls from 'classnames'
import { getEnv } from 'utils/envUtils'

import Style from './EnvironmentBanner.module.scss'

type Env = 'DEV' | 'QA' | 'UAT' | 'PROD'

const getEnvironmentType = (): Env => {
  switch (getEnv()) {
    case 'G1-PROD':
      return 'PROD'
    case 'G1-UAT':
      return 'UAT'
    case 'G1-QA':
      return 'QA'
    case 'G1-DEV':
      return 'DEV'
    default:
      return 'PROD'
  }
}

type EnvironmentBannerProps = { className?: string }

export const EnvironmentBanner: FC<EnvironmentBannerProps> = ({
  className,
}) => {
  const env = getEnvironmentType()

  if (env === 'PROD') {
    return null
  }

  return (
    <div
      className={cls(Style.envBanner, className, {
        [Style.uat]: env === 'UAT',
      })}
    >
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="textPattern"
            patternUnits="userSpaceOnUse"
            width="100"
            height="40"
          >
            <text x="48" y="24" className={Style.text}>
              {env}
            </text>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#textPattern)" />
      </svg>
    </div>
  )
}
